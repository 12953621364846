import React, { useEffect, useMemo, useState } from "react";
import { navigate, useLocation } from "@reach/router";
import { PrimaryButton } from "@metyis-ds/button";
import { IContent } from "../contexts/ContentContext";
import { IDomain } from "./domain";
import { IDropdown } from "../components/dropdown/Dropdown";
import { IDropdownItem } from "../components/dropdown/types";
import { IJob } from "../components/jobOffering/types";
import { ILocation } from "./locations";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import CTABanner from "../components/banner/CTABanner";
import Filter from "../components/filter/Filter";
import JobOfferingList from "../components/jobOffering/JobOfferingList";
import Page from "../components/page/Page";
import RedirectBanner from "../components/banner/RedirectBanner";
import { jobsMeta } from "./meta";
import "./jobs.css";

const NUM_JOBS_TO_SHOW = 12;

export default ({
  pageContext
}: {
  pageContext: { content: IContent; domains: IDomain[]; jobs: IJob[] };
}) => {
  const location = useLocation();
  const { content, domains, jobs } = pageContext;
  const searchParams = location.search.substring(1).split("&");
  let initialFilterLocation = "";
  let initialFilterDomain = "";
  let initialFilterSeniority = "";

  searchParams.forEach(searchParam => {
    const value = searchParam.split("=")?.[1];
    if (searchParam.includes("location=")) {
      const locationIndex = content.locations.findIndex(
        location =>
          location.name.toLowerCase().replace(/[ ]/g, "") ===
          value.toLowerCase().replace(/[\_\-]/g, "")
      );

      initialFilterLocation =
        locationIndex !== -1 ? content.locations[locationIndex].name : "";
    }

    if (searchParam.includes("domain=")) {
      const domainIndex = domains.findIndex(
        domain =>
          domain.id.toLowerCase().replace(/[\_\-]/g, "") ===
          value.toLowerCase().replace(/[\_\-]/g, "")
      );

      initialFilterDomain = domainIndex !== -1 ? domains[domainIndex].id : "";
    }

    if (searchParam.includes("seniority=")) {
      const seniorities = ["Senior", "Mid", "Junior"];
      const seniorityIndex = seniorities.findIndex(
        seniority => seniority.toLowerCase() === value.toLowerCase()
      );

      initialFilterSeniority =
        seniorityIndex !== -1 ? seniorities[seniorityIndex] : "";
    }
  });

  const [groupOfJobsNr, setGroupOfJobsNr] = useState(1);
  const [lastChangedFilter, setLastChangedFilter] = useState<string>();
  const [filterDomain, setFilterDomain] = useState<string>(initialFilterDomain);
  const [filterLocation, setFilterLocation] = useState(initialFilterLocation);
  const [filterSeniority, setFilterSeniority] = useState(
    initialFilterSeniority
  );

  // update URL after filters are changed
  useEffect(() => {
    if (!!lastChangedFilter) {
      const queryParams = location.search;
      let newQueryParams = "";

      // when specific filter is removed
      if (
        (lastChangedFilter === "location" && !filterLocation) ||
        (lastChangedFilter === "domain" && !filterDomain) ||
        (lastChangedFilter === "seniority" && !filterSeniority)
      ) {
        // remove that filter's query param from the URL
        newQueryParams =
          queryParams.includes(lastChangedFilter) && queryParams.includes("&")
            ? queryParams.replace(
                new RegExp(`${lastChangedFilter}=[a-zA-Z\-\_]+`),
                ""
              )
            : "";

        // clean up remaining query params
        newQueryParams =
          newQueryParams === "?"
            ? ""
            : newQueryParams.startsWith("?&")
            ? newQueryParams.replace("?&", "?")
            : newQueryParams.endsWith("&")
            ? newQueryParams.substring(0, newQueryParams.length - 1)
            : newQueryParams.includes("&&")
            ? newQueryParams.replace("&&", "&")
            : newQueryParams;

        // when specific filter is added
      } else {
        let param =
          lastChangedFilter === "location"
            ? filterLocation
            : lastChangedFilter === "domain"
            ? filterDomain
            : filterSeniority;
        param = param.toLowerCase().replace(/[ ]/g, "-");

        // add or replace new query param for that filter in the URL
        newQueryParams = !queryParams
          ? `?${lastChangedFilter}=${param}`
          : queryParams.includes(lastChangedFilter)
          ? queryParams.replace(
              new RegExp(`${lastChangedFilter}=[a-zA-Z\-\_]+`),
              `${lastChangedFilter}=${param}`
            )
          : `${queryParams}&${lastChangedFilter}=${param}`;
      }

      // update browser history
      navigate(`${location.origin}${location.pathname}${newQueryParams}`, {
        replace: true
      });
    }
  }, [filterDomain, filterLocation, filterSeniority]);

  const domainsItems: IDropdownItem[] = useMemo(
    () => [
      {
        id: "",
        value: "All domains"
      },
      ...domains
        .sort((domainA, domainB) => (domainA.name > domainB.name ? 1 : -1))
        .map((domain: IDomain) => ({
          id: domain.id,
          value: domain.name
        }))
    ],
    [domains]
  );

  const locations: IDropdownItem[] = useMemo(
    () => [
      {
        id: "",
        value: "All locations"
      },
      ...content.locations.map((location: ILocation) => ({
        id: location.name,
        value: location.name
      })),
      {
        id: "Others",
        value: "Others"
      }
    ],
    [content.locations]
  );

  const seniorities: IDropdownItem[] = [
    {
      id: "",
      value: "All seniorities"
    },
    {
      id: "Junior",
      value: "Junior"
    },
    {
      id: "Mid",
      value: "Mid"
    },
    {
      id: "Senior",
      value: "Senior"
    }
  ];

  const dropdowns: IDropdown[] = [
    {
      items: locations,
      selectedItem: initialFilterLocation
        ? locations.find(location => initialFilterLocation === location.id)
        : locations[0],
      onItemSelected: (loc: IDropdownItem) => {
        setFilterLocation(loc.id);
        setLastChangedFilter("location");
        handleResetGroupOfJobsNr();
      }
    },
    {
      items: domainsItems,
      selectedItem: initialFilterDomain
        ? domainsItems.find(domain => domain.id === initialFilterDomain)
        : domainsItems[0],
      onItemSelected: (domain: IDropdownItem) => {
        setFilterDomain(domain.id);
        setLastChangedFilter("domain");
        handleResetGroupOfJobsNr();
      }
    },
    {
      items: seniorities,
      selectedItem: initialFilterSeniority
        ? seniorities.find(
            seniority => seniority.value === initialFilterSeniority
          )
        : seniorities[0],
      onItemSelected: (seniority: IDropdownItem) => {
        setFilterSeniority(seniority.id);
        setLastChangedFilter("seniority");
        handleResetGroupOfJobsNr();
      }
    }
  ];

  const filteredJobs = useMemo(
    () =>
      jobs.filter(
        (job: IJob) =>
          (!filterDomain ||
            (job.domain?.id && job.domain?.id?.indexOf(filterDomain) !== -1)) &&
          (job.jobLocation
            .toLowerCase()
            .indexOf(filterLocation.toLowerCase()) !== -1 ||
            (filterLocation === "Others" &&
              !content.locations.find(loc => loc.name === job.jobLocation))) &&
          job.seniority.toLowerCase().indexOf(filterSeniority.toLowerCase()) !==
            -1
      ),
    [filterDomain, filterLocation, filterSeniority, jobs]
  );

  const handleShowMore = () => {
    setGroupOfJobsNr(prevNr => prevNr + 1);
  };

  const handleResetGroupOfJobsNr = () => {
    setGroupOfJobsNr(1);
  };

  return (
    <Page
      content={content}
      seo={{
        description: jobsMeta.description,
        image: jobsMeta.image,
        title: jobsMeta.title
      }}
    >
      <BreadCrumbs
        crumbsArray={[
          {
            name: "Careers",
            redirectUrl: "/careers"
          },
          {
            name: "Available positions"
          }
        ]}
      />
      <section className="jobs">
        <div className="jobs-list">
          <Filter
            dropdowns={dropdowns}
            mobileLabel="Filter available positions"
            numResults={filteredJobs.length}
            isSticky
          />
          <JobOfferingList
            jobs={filteredJobs.slice(0, groupOfJobsNr * NUM_JOBS_TO_SHOW)}
            title="Available positions"
          />
          {groupOfJobsNr * NUM_JOBS_TO_SHOW < filteredJobs.length && (
            <div className="show-more">
              <span className="show-more-label">
                Showing {groupOfJobsNr * NUM_JOBS_TO_SHOW} out of{" "}
                {filteredJobs.length}
              </span>
              <PrimaryButton onClick={handleShowMore}>Load more</PrimaryButton>
            </div>
          )}
        </div>
        <RedirectBanner
          backgroundColor="var(--color-blue)"
          subtitle="We are always on the lookout for top talent. Whether you are a recent graduate, postdoc or an experienced professional."
          subtitleColor="var(--color-yellow)"
          textColor="var(--color-white)"
          title="Spontaneous application"
          redirectUrl="/application"
        />
        <CTABanner
          backgroundColor="var(--color-orange)"
          image={{
            file: {
              url:
                "//images.ctfassets.net/zuzzsqg9enty/5e3ixYoPd1af9ges7Q6tL9/d9aea305680b0a936eeab448bae62f0a/IMG_2622_2.jpg"
            },
            title: "Coworkers laughing at the table"
          }}
          redirectUrl="/culture"
          text={{
            text:
              "The first step towards impact is bringing together a global team that inspires change, embraces imperfection and keeps evolving. Take a deep dive into our vibrant culture."
          }}
          textColor="var(--color-brown)"
          title="Our culture"
        />
      </section>
    </Page>
  );
};

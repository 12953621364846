import React, { useState } from "react";
import classnames from "classnames";
import { SecondaryButton } from "@metyis-ds/button";
import { Link } from "../Link/LinkWithPrevUrl";
import { IJob } from "./types";
const getFriendlyUrl = require("../../helpers/helpers.ts").getFriendlyUrl;
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import "./jobOffering.css";

const JobOfferingSummary: React.FC<IJob> = ({
  isSmall,
  jobLocation,
  seniority,
  summary,
  title
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Link to={getFriendlyUrl("job", `${title} ${seniority} ${jobLocation}`)}>
      <FadeinDiv
        className={classnames("job-offering-summary", {
          "job-offering-summary--small": isSmall
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="job-offering-info">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="job-offering-seniority">{seniority}</span>
            <span className="job-offering-title">{title}</span>
          </div>
          <span className="job-offering-location">{jobLocation}</span>
          {!isSmall && summary ? (
            <p className="job-offering-description">
              {summary.summary.length > 300
                ? `${summary.summary.substring(0, 281)}...`
                : summary.summary}
            </p>
          ) : (
            <></>
          )}
        </div>
        {!isSmall && (
          <SecondaryButton
            backgroundColor="var(--color-blue-dark)"
            className={classnames({ hover: isHovering })}
            color="var(--color-white)"
          >
            Apply
          </SecondaryButton>
        )}
      </FadeinDiv>
    </Link>
  );
};

export default JobOfferingSummary;

import React from "react";
import { IJobList } from "./types";
import JobOfferingSummary from "./JobOfferingSummary";
import DoubleLineTitle from "../doubleLineTitle/DoubleLineTitle";
import "./jobOffering.css";

const JobOfferingList: React.FC<IJobList> = ({
  isSmall = false,
  jobs,
  title,
  title2,
  titleColor,
  title2Color
}) => (
  <div className="job-offering-list">
    <DoubleLineTitle
      className="job-offering-list-title"
      title={title}
      title2={title2}
      titleColor={titleColor}
      title2Color={title2Color}
    />
    <div className="job-offering-list-jobs">
      {jobs?.map(job => (
        <JobOfferingSummary key={job.id} {...job} isSmall={isSmall} />
      ))}
      {(!jobs || jobs.length === 0) && (
        <span style={{ fontSize: 24 }}>There are no positions available</span>
      )}
    </div>
  </div>
);

export default JobOfferingList;
